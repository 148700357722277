<template>

    <div style="position: relative;">

        <div class="userinfoBox">


            <!-- <img :src="userInfo.avatar" alt=""> -->


            <el-upload drag class="SelfUploadBox" action="/api/admin/uploadImage" :headers="Headers" name="image"
                list-type="picture-card" :limit="1" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                :before-upload="beforeAvatarUpload" :show-file-list="true" :file-list="photoList">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖拽到此处或点击上传</div>
            </el-upload>


            <p class="name">{{userInfo.nickname}} {{userInfo.realname}}</p>
            <p class="info">{{userInfo.intro}}</p>
            <!-- <p>身份证号：{{userInfo.identity}}</p> -->
            <p>岗位：{{station}} </p>
            <p>科室：{{dept.name}} </p>


            <!-- {{mypower.map(x=>{
                return x.name
            }).toString()}} -->

            <!-- <p>所属城市：{{userInfo.city_name}}</p>  -->



        </div>


        <el-tabs v-model="activeName" @tab-click="handleClick" class="userinfoBoxII">
            <el-tab-pane label="基本信息" name="first">

                <el-form v-if="formShow" ref="form" label-width="80px">

                    <el-form-item label="真实姓名" prop="title" class="redstar">
                        <el-input v-model="upUserInfo.realname" />
                    </el-form-item>

                    <el-form-item label="邮箱" prop="title" class="redstar">
                        <el-input v-model="upUserInfo.email" />
                    </el-form-item>

                    <el-form-item label="个人简介" prop="title">
                        <el-input autosize type="textarea" :rows="2" placeholder="请输入内容" v-model="upUserInfo.intro" />
                        </el-input>
                    </el-form-item>

                    <el-form-item label="街道地址" prop="title">
                        <el-input v-model="upUserInfo.address" />
                    </el-form-item>

                    <el-form-item label="联系电话" prop="title">
                        <el-input v-model="upUserInfo.mobile" />
                    </el-form-item>

                    <el-button type="primary" @click="save">保存</el-button>

                </el-form>

            </el-tab-pane>


            <el-tab-pane label="账号绑定" name="second">

                <el-form v-if="formShow" ref="form" :model="userInfo" label-width="80px">

                    <h1>密保手机</h1>
                    <el-form-item label="绑定手机" prop="title">
                        <el-input v-model="userInfo.phoneBind" />
                    </el-form-item>

                    <h1>密保邮箱</h1>
                    <el-form-item label="绑定邮箱" prop="title">
                        <el-input v-model="userInfo.mailBind" />
                    </el-form-item>

                    <el-button type="primary" @click="save">保存</el-button>

                </el-form>

            </el-tab-pane>

        </el-tabs>




    </div>

</template>

<script>
    export default {



        data() {
            return {
                activeName: 'first',
                formShow: true,
                num: 1,

                userInfo: {},
                upUserInfo: {},

                power: [],
                mypower: [],
                mypowerList: [],


                // 回显图片的路径
                photoList: [],
                // 图片的路径
                dialogImageUrl: '',




                dept: {}
            };
        },

        mounted() {
            this.userInfo = JSON.parse(localStorage.getItem("user"))

            this.userInfo.avatar = this.userInfo.avatar

            this.userInfo.role_ids = this.userInfo.role_ids.split(',')
            this.mypowerList = this.userInfo.role_ids

            this.upUserInfo = {
                address: this.userInfo.address,
                email: this.userInfo.email,
                intro: this.userInfo.intro,
                realname: this.userInfo.realname,
                mobile: this.userInfo.mobile,
                username: this.userInfo.username
            }


            console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~", this.userInfo.avatar.length);

            if (this.userInfo.avatar.length > 0) {

                this.dialogImageUrl = this.userInfo.avatar
                this.photoList = [{
                    url: this.GLOBAL.BASE_URL + '/uploads' + this.userInfo.avatar
                }]

            }



            this.getDep(this.userInfo.dept_id)

            // if (this.power.length == 0) {
            this.getPower()
            // }







        },

        computed: {
            station() {
                if (this.userInfo.type) {
                    var a = this.userInfo.type.split(',').map(x => {
                        switch (Number(x)) {
                            case 1:
                                return "收集人"
                                break;
                            case 2:
                                return "入库人"
                                break;
                            case 3:
                                return "出库人"
                                break;

                            default:
                                return "未知"
                                break;
                        }
                    })
                    return a.toString()
                } else {
                    return ""
                }
            },


            Headers() {
                let token = localStorage.getItem('token')
                return {
                    token
                }
            }


        },
        methods: {


            getPower() {
                this.$http.get('/api/menu/tree').then((res) => {
                    if (res.code == 200) {
                        this.power = res.data

                        this.formatTree(this.power, 0)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },

            getDep(val) {
                this.$http.get('/api/dep/' + val).then((res) => {
                    if (res.code == 200) {
                        this.dept = res.data
                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },




            formatTree(tree, val) {
                if (!tree || !tree.length) return;
                for (const node of tree) {
                    node.level = val

                    if (this.mypowerList.includes(node.id)) {
                        this.mypower.push(node)
                    }

                    if (node.children && node.children.length) {
                        this.formatTree(node.children, val + 1)
                    } else {

                        // this.selectArray.unshift(node.id)
                        // this.selectArray = Object.values(this.selectArray)

                    }
                }
                return tree
            },



            handleClick(tab, event) {
                console.log(tab, event);
            },

            save() {

                // this.dialogImageUrl = info.avatar
                // this.photoList = [{
                //     url: this.GLOBAL.BASE_URL + info.avatar
                // }]

                this.upUserInfo.avatar = this.dialogImageUrl



                this.$http.put('/api/admin/' + this.userInfo.id, this.upUserInfo).then((res) => {
                    if (res.code == 200) {

                        var a = JSON.parse(localStorage.getItem("user"))

                        a.address = this.upUserInfo.address
                        a.avatar = this.upUserInfo.avatar
                        a.email = this.upUserInfo.email
                        a.intro = this.upUserInfo.intro
                        a.mobile = this.upUserInfo.mobile
                        a.realname = this.upUserInfo.realname

                        localStorage.setItem('user', JSON.stringify(a)) //保存数据


                        this.$message.success(res.msg);

                        this.$router.go(0)



                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });

            },




            handleRemove(file, fileList) {
                console.log(file, fileList)
                this.dialogImageUrl = ''
            },
            handleAvatarSuccess(res, file) {
                this.dialogImageUrl = res.data
                console.log(this.dialogImageUrl)
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2

                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!')
                }
                return /* isJPG && */ isLt2M
            }





        }
    };
</script>

<style>
    .userinfoBox {
        width: 300px;
        height: 500px;

        /* border: 1px red dotted;
        box-sizing: content-box; */

        background: #F9F9F9;
        box-shadow: 4px 1px 8px 0px rgba(206, 206, 206, 0.56);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        float: left;
        padding: 20px;
    }

    /* .userinfoBox img, */
    .userinfoBox .SelfUploadBox {

        /* border: 1px red dotted;
        box-sizing: content-box; */


        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
        margin-top: 50px;




    }

    .el-upload--picture-card {
        border: none;
        /* background-color: red; */
    }





    .userinfoBox p {
        line-height: 40px;
        font-weight: 300;
        font-size: 14px;
        /* border: 1px red dotted; */
        /* box-sizing: content-box; */
    }

    .userinfoBox p.name {
        font-size: 24px;
        font-weight: 400;
        color: #606060;
        line-height: 60px;
    }

    .userinfoBox p.info {
        line-height: 24px;
        margin: -10px 0 10px 0;
        text-indent: 2em;
    }


    .userinfoBoxII {
        float: left;
        margin-left: 10px;
        position: absolute;
        right: 0;
        left: 360px;
    }

    .userinfoBoxII .redstar .el-form-item__label::before {
        content: "*";
        color: red;
    }

    .userinfoBoxII input,
    .userinfoBoxII textarea {
        max-width: 300px;
        padding: 3px 10px;
    }

    .userinfoBoxII button {
        position: relative;
        left: 80px;
        padding: 0px 30px;
        margin-top: 10px;
    }

    .userinfoBoxII h1 {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6A83FC;
        line-height: 60px;

    }
</style>